
.NotificationSender {
    
}

.formWrapper {
    max-width: 100%;
    width: 600px;
    padding: 20px;

}

.form {
    
}

.title,
.body,
.url,
.image {
    width: 100%;
}
