
.LoadingContainer {
  position: relative;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: none;
  align-items: center;
  justify-content: center;
}

.overlay.isLoading {
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
}

