
.auth {
    min-height: 100vh;
}

.paper {
    width: 400px;
    max-width: 100%;
}

.fullHeight {
    height: 100vh;
}

.fullWidth {
    width: 100%;
}

.marginZero {
    margin: 0;
}

.email {
    margin: 0;
    width: 100%;
}

.password {
    margin: 0;
    width: 100%;
}

.username {
    margin: 0;
    width: 100%;
}
