.LoginFirst {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.card {
  max-width: 300px;
  position: relative;
}

.cardContent {
  position: relative;
  white-space: pre-wrap;
  word-break: normal;
}

.surface {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}