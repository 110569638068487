
.filler {
    position: absolute;
    left: 0; right: 0; top: 0; bottom: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.6);
    text-align: center;
    padding-top: 40vh;
}

.text {
    color: #fff;
}
